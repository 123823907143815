import React from 'react';
import { 
  Typography, 
  Paper, 
  Box, 
  Grid, 
  Chip 
} from '@mui/material';
import { Event } from '../../types/Event';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PeopleIcon from '@mui/icons-material/People';
import PaymentIcon from '@mui/icons-material/Payment';
import InfoIcon from '@mui/icons-material/Info';
import { formatDate, getDayOfWeek } from './utils';

interface EventDetailsProps {
  event: Event;
  currentParticipantCount: number;
  spotsLeft: number;
  isEventFull: boolean;
}

const EventDetails: React.FC<EventDetailsProps> = ({ 
  event, 
  currentParticipantCount, 
  spotsLeft, 
  isEventFull 
}) => (
  <Paper elevation={0} sx={{ px: 3, py: 2, bgcolor: 'background.paper', borderRadius: 2 }}>
    <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', mb: 2 }}>
      活動詳情
    </Typography>
    
    <Grid container>
      <Grid item xs={12} sm={6}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <CalendarTodayIcon sx={{ mr: 1, color: 'secondary.main' }} />
          <Typography variant="body1">
            {formatDate(event.date)} ({getDayOfWeek(event.date)})
          </Typography>
        </Box>
        
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <AccessTimeIcon sx={{ mr: 1, color: 'secondary.main' }} />
          <Typography variant="body1">{event.time}</Typography>
        </Box>
        
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <LocationOnIcon sx={{ mr: 1, color: 'secondary.main' }} />
          <Typography variant="body1">{event.location}</Typography>
        </Box>
      </Grid>
      
      <Grid item xs={12} sm={6}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <PaymentIcon sx={{ mr: 1, color: 'secondary.main' }} />
          <Typography variant="body1">
            ${event.memberFee} / ${event.normalFee}
          </Typography>
        </Box>
        
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <PeopleIcon sx={{ mr: 1, color: 'secondary.main' }} />
          <Typography variant="body1">
            已報名: {currentParticipantCount}/{event.maxParticipants ?? 0}
            {spotsLeft > 0 && (
              <Chip 
                size="small" 
                label={`剩餘 ${spotsLeft} 個名額`} 
                color="success"
                variant="outlined" 
                sx={{ ml: 1 }}
              />
            )}
            {isEventFull && (
              <Chip 
                size="small" 
                label="名額已滿" 
                color="error" 
                variant="outlined" 
                sx={{ ml: 1 }}
              />
            )}
          </Typography>
        </Box>
        
        {event.otherDetails && (
          <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 2 }}>
            <InfoIcon sx={{ mr: 1, color: 'secondary.main' }} />
            <Typography variant="body1">{event.otherDetails}</Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  </Paper>
);

export default EventDetails;