import { Event } from '../../types/Event';

// Helper functions
export const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}/${month}/${day}`;
};

export const getDayOfWeek = (dateString: string): string => {
  const daysOfWeek = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
  const date = new Date(dateString);
  return daysOfWeek[date.getDay()];
};

// Generate ICS file content
export const generateICSContent = (event: Event, spots: number): string => {
  // Format the event date and time
  const eventDate = new Date(event.date);
  const [startHours, startMinutes] = event.time.split(' - ')[0].split(':').map(Number);
  const [endHours, endMinutes] = event.time.split(' - ')[1].split(':').map(Number);

  eventDate.setHours(startHours);
  eventDate.setMinutes(startMinutes);

  const endDate = new Date(event.date);
  endDate.setHours(endHours);
  endDate.setMinutes(endMinutes);
  
  // Convert to Hong Kong timezone (UTC+8)
  const formatDateToHKTime = (date: Date): string => {
    // Create a date string in ISO format but with Hong Kong timezone offset
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    
    // Format as YYYYMMDDTHHMMSS with Hong Kong timezone
    return `${year}${month}${day}T${hours}${minutes}${seconds}Z`;
  };
  
  const startTime = formatDateToHKTime(eventDate);
  const endTime = formatDateToHKTime(endDate);
  const now = formatDateToHKTime(new Date());
  
  // Create unique identifier for the event
  const uid = `${event._id}@${window.location.hostname}`;
  
  // Build iCalendar content
  return [
    'BEGIN:VCALENDAR',
    'VERSION:2.0',
    'CALSCALE:GREGORIAN',
    'METHOD:PUBLISH',
    'BEGIN:VEVENT',
    `UID:${uid}`,
    `DTSTAMP:${now}`,
    `DTSTART;TZID=Asia/Hong_Kong:${startTime}`,
    `DTEND;TZID=Asia/Hong_Kong:${endTime}`,
    `SUMMARY:${event.location ?? ''}${event.otherDetails ?? ''}`,
    `DESCRIPTION:${event.sendDetails || ''}\n參與人數: ${spots} 位`,
    `LOCATION:${event.location || ''}`,
    'END:VEVENT',
    'END:VCALENDAR'
  ].join('\r\n');
};