import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  TextField, 
  Box, 
  Button, 
  Alert, 
  CircularProgress, 
  Typography 
} from '@mui/material';
import { useSignupStore } from '../../stores/SignupStore';
import axios from 'axios';
import { API_BASE_URL } from '../../config';

function PhoneVerification() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  
  const { 
    phone,
    verificationCode,
    isVerified,
    isVerificationSent,
    verificationError,
    countdown,
    setVerificationCode,
    setIsVerified,
    setIsVerificationSent,
    setVerificationError,
    setCountdown,
    decrementCountdown
  } = useSignupStore();

  useEffect(() => {
    if (!phone) {
      navigate('/signup/account-setup');
    }
  }, [phone, navigate]);

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => decrementCountdown(), 1000);
      return () => clearTimeout(timer);
    }
  }, [countdown, decrementCountdown]);

  const sendVerificationCode = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(`${API_BASE_URL}/auth/send-verification`, {
        phoneNumber: `+852${phone}`
      });
      
      if (response.data.success) {
        setIsVerificationSent(true);
        setCountdown(60);
      } else {
        setVerificationError('發送驗證碼失敗');
      }
    } catch (err) {
      setVerificationError('發送驗證碼時發生錯誤');
    } finally {
      setIsLoading(false);
    }
  };

  const verifyCode = async () => {
    setIsLoading(true);
    setVerificationError('');
    
    try {
      const response = await axios.post(`${API_BASE_URL}/auth/verify-code`, {
        phoneNumber: `+852${phone}`,
        code: verificationCode
      });
      
      if (response.data.success) {
        setIsVerified(true);
      } else {
        setVerificationError('驗證碼無效');
      }
    } catch (err: any) {
      if (axios.isAxiosError(err) && err.response) {
        setVerificationError(err.response.data.message || '驗證失敗');
      } else {
        setVerificationError('驗證時發生錯誤');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleNext = () => {
    if (isVerified) {
      navigate('/signup/confirmation');
    }
  };

  return (
    <Box>
      {verificationError && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {verificationError}
        </Alert>
      )}

      {!isVerificationSent ? (
        <>
          <Typography variant="body1" sx={{ mb: 2 }}>
            請點擊下方按鈕發送驗證碼到 +852 {phone}
          </Typography>
          <Button
            variant="contained"
            fullWidth
            onClick={sendVerificationCode}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : '發送驗證碼'}
          </Button>
        </>
      ) : (
        <>
          <Typography variant="body1" sx={{ mb: 2 }}>
            我們已發送驗證碼到 +852 {phone}
          </Typography>

          <TextField
            required
            fullWidth
            label="驗證碼"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            disabled={isLoading || isVerified}
            inputProps={{
              maxLength: 6,
              pattern: "[0-9]*",
              inputMode: "numeric",
              autoComplete: "one-time-code"
            }}
            helperText="請輸入6位數驗證碼"
            error={!!verificationError}
            sx={{ mb: 2 }}
          />

          {!isVerified ? (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
              <Button
                variant="text"
                onClick={sendVerificationCode}
                disabled={isLoading || countdown > 0}
              >
                {countdown > 0 ? `重新發送 (${countdown}s)` : '重新發送驗證碼'}
              </Button>
              
              <Button
                variant="contained"
                onClick={verifyCode}
                disabled={isLoading || !verificationCode || verificationCode.length !== 6}
              >
                {isLoading ? <CircularProgress size={24} /> : '驗證'}
              </Button>
            </Box>
          ) : (
            <>
              <Alert severity="success" sx={{ mt: 2 }}>
                電話號碼驗證成功！
              </Alert>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
                <Button
                  variant="outlined"
                  onClick={() => navigate('/signup/account-setup')}
                >
                  上一步
                </Button>
                <Button
                  variant="contained"
                  onClick={handleNext}
                >
                  下一步
                </Button>
              </Box>
            </>
          )}
        </>
      )}
    </Box>
  );
}

export default PhoneVerification;