import React from 'react';
import { 
  Typography, 
  Button, 
  Box, 
  Paper 
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Event } from '../../types/Event';
import { formatDate, getDayOfWeek } from './utils';

interface ConfirmationViewProps {
  event: Event;
  spots: number;
  isWaitlisted: boolean;
  requiresApproval: boolean;
  isAuthenticated: boolean;
  addToCalendar: () => void;
  onClose: () => void;
}

const ConfirmationView: React.FC<ConfirmationViewProps> = ({ 
  event, 
  spots, 
  isWaitlisted, 
  requiresApproval, 
  isAuthenticated, 
  addToCalendar, 
  onClose 
}) => (
  <Box sx={{ textAlign: 'center', py: 3 }}>
    <CheckCircleIcon sx={{ fontSize: 80, color: 'success.main', mb: 2 }} />
    <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: 'success.main' }}>
      {isWaitlisted ? '已加入候補名單！' : '報名成功！'}
    </Typography>
    
    {requiresApproval ? (
      <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold', }}>
        由於這是您第一次參加，需要等待核准。核准後您將收到確認訊息。
      </Typography>
    ) : !isWaitlisted && (
      <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold', }}>
        請留意WhatsApp確認信息
      </Typography>
    )}
    
    <Paper elevation={0} sx={{ p: 3, my: 2, bgcolor: 'background.paper', borderRadius: 2, maxWidth: 400, mx: 'auto' }}>
      <Typography variant="body1" align="left" paragraph>
        <strong>日期：</strong> {formatDate(event.date)} ({getDayOfWeek(event.date)})
      </Typography>
      <Typography variant="body1" align="left" paragraph>
        <strong>時間：</strong> {event.time}
      </Typography>
      <Typography variant="body1" align="left" paragraph>
        <strong>地點：</strong> {event.location}
      </Typography>
      <Typography variant="body1" align="left" paragraph>
        <strong>費用：</strong> ${isAuthenticated ? event.memberFee : event.normalFee}
      </Typography>
      <Typography variant="body1" align="left">
        <strong>人數：</strong> {spots} 位
      </Typography>
    </Paper>
    {!requiresApproval && (
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 2 }}>
        <Button
          variant="outlined"
          color="primary"
          onClick={addToCalendar}
          startIcon={<CalendarTodayIcon />}
        >
          加入日曆
        </Button>
        
        <Button
          variant="contained"
          color="primary"
          onClick={onClose}
        >
          完成
        </Button>
      </Box>
    )}
  </Box>
);

export default ConfirmationView;