import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Box, 
  Button, 
  Alert, 
  CircularProgress, 
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper
} from '@mui/material';
import { 
  CheckCircle, 
  Person, 
  Web, 
  Link as LinkIcon, 
  Phone, 
} from '@mui/icons-material';
import { useSignupStore } from '../../stores/SignupStore';
import { useAuth } from '../../contexts/AuthContext';
import axios from 'axios';
import { API_BASE_URL } from '../../config';

function Confirmation() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const { login } = useAuth();
  
  const { 
    username,
    siteName,
    route,
    phone,
    password,
    reset,
    isVerified
  } = useSignupStore();

  // Redirect if required fields are missing
  React.useEffect(() => {
    if (!username || !siteName || !route || !phone || !password) {
      navigate('/signup/personal-info');
    }
  }, [username, siteName, route, phone, password, navigate]);

  const handleSubmit = async () => {
    setIsLoading(true);
    setError('');

    try {
      const response = await axios.post(`${API_BASE_URL}/auth/signup`, {
        username,
        siteName,
        route,
        phoneNumber: `+852${phone}`,
        password,
        verified: isVerified
      });

      if (response.data.success) {
        // Clear signup store
        reset();
        // Navigate to the user's site route
        const { token, route: responseRoute } = response.data;
        login(token);
        navigate(`/${responseRoute}`);
      } else {
        setError(response.data.message || '註冊失敗，請稍後再試');
      }
    } catch (err: any) {
      if (axios.isAxiosError(err) && err.response) {
        setError(err.response.data.message || '註冊失敗，請稍後再試');
      } else {
        setError('註冊時發生錯誤');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Typography variant="h6" gutterBottom>
        請確認以下資料
      </Typography>

      <Paper variant="outlined" sx={{ p: 2, mb: 3 }}>
        <List disablePadding>
          <ListItem>
            <ListItemIcon>
              <Person color="primary" />
            </ListItemIcon>
            <ListItemText 
              primary="用戶名"
              secondary={username}
              sx={{ wordBreak: 'break-all' }}
            />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <Web color="primary" />
            </ListItemIcon>
            <ListItemText 
              primary="網站名稱"
              secondary={siteName}
              sx={{ wordBreak: 'break-all' }}
            />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <LinkIcon color="primary" />
            </ListItemIcon>
            <ListItemText 
              primary="網站網址"
              secondary={`https://matchpoint.hk/${route}`}
              sx={{ wordBreak: 'break-all' }}
            />
          </ListItem>

          <ListItem>
            <ListItemIcon>
              <Phone color="primary" />
            </ListItemIcon>
            <ListItemText 
              primary="電話號碼"
              secondary={`+852 ${phone}`}
            />
            <CheckCircle color="success" sx={{ ml: 1 }} />
          </ListItem>
        </List>
      </Paper>

      <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
        提交註冊即表示您同意我們的
        <Button 
          variant="text" 
          size="small"
        //   onClick={() => window.open('/terms', '_blank')}
        >
          服務條款
        </Button>
        和
        <Button 
          variant="text" 
          size="small"
        //   onClick={() => window.open('/privacy', '_blank')}
        >
          隱私政策
        </Button>
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
        <Button
          variant="outlined"
          onClick={() => navigate('/signup/phone-verification')}
          disabled={isLoading}
        >
          上一步
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress size={24} />
          ) : (
            '提交註冊'
          )}
        </Button>
      </Box>
    </Box>
  );
}

export default Confirmation;