import React from 'react';
import { 
  Typography, 
  Button, 
  List, 
  ListItem, 
  ListItemText, 
  Collapse, 
  Box, 
  Paper, 
  Chip 
} from '@mui/material';
import { Event } from '../../types/Event';

interface ParticipantsListProps {
  event: Event;
  isAuthenticated: boolean;
  showParticipants: boolean;
  setShowParticipants: (show: boolean) => void;
}

const ParticipantsList: React.FC<ParticipantsListProps> = ({ 
  event, 
  isAuthenticated, 
  showParticipants, 
  setShowParticipants 
}) => (
  <Box>
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          參與者名單
        </Typography>
      </Box>
      <Button
        size="small"
        color="secondary"
        variant="outlined"
        onClick={() => setShowParticipants(!showParticipants)}
      >
        {showParticipants ? '隱藏名單' : '顯示名單'}
      </Button>
    </Box>
    
    <Collapse in={showParticipants}>
      <Paper variant="outlined" sx={{ px: 2, borderRadius: 2 }}>
        <List dense disablePadding>
          {event.participants && event.participants.length > 0 ? (
            event.participants.map((participant, index) => (
              <ListItem key={index} divider={index < event.participants!.length - 1}>
                <ListItemText
                  primary={
                    <Typography variant="body1" sx={{ fontWeight: 'medium' }}>
                      {participant.name} <Chip size="small" label={`${participant.spots} 位`} sx={{ ml: 1 }} />
                    </Typography>
                  }
                  secondary={isAuthenticated ? `電話: ${participant.phone}` : null}
                />
              </ListItem>
            ))
          ) : (
            <ListItem>
              <ListItemText primary="目前沒有參與者" />
            </ListItem>
          )}
        </List>
      </Paper>
    </Collapse>
  </Box>
);

export default ParticipantsList;