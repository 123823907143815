import React from 'react';
import { Event } from '../../types/Event';
import { useJoinModal } from './useJoinModal';
import JoinModalUI from './JoinModalUI';

interface Props {
  event: Event;
  onClose: () => void;
  onEventUpdated: (updatedEvent: Event) => void;
}

const JoinModal: React.FC<Props> = ({ event, onClose, onEventUpdated }) => {
  // All logic is now in the custom hook
  const joinModalProps = useJoinModal(event, onClose, onEventUpdated);
  
  // Just pass all the props to the UI component
  return <JoinModalUI {...joinModalProps} />;
};

export default JoinModal;