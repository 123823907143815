import React from 'react';
import { Button, Card, CardContent, Typography, Box, IconButton, Chip } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EditIcon from '@mui/icons-material/Edit';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Event } from '../types/Event';

interface Props {
  event: Event;
  onJoin: (event: Event) => void;
  onManage: (event: Event) => void;
  isAuthenticated: boolean;
  siteSettings?: {
    whatsappMessageTemplate?: string;
    defaultSenderId?: string;
  };
}

const EventButton: React.FC<Props> = ({ event, onJoin, onManage, isAuthenticated, siteSettings }) => {
  const shareToWhatsApp = (e: React.MouseEvent) => {
    e.stopPropagation();
    let message = encodeURIComponent(
      `查詢以下活動\n\n日期：${event.date}\n時間：${event.time}\n地點：${event.location}`
    );
    
    if (isAuthenticated) {
      // Use custom template if available, otherwise use default
      if (siteSettings?.whatsappMessageTemplate) {
        // Replace placeholders with actual values
        let customMessage = siteSettings.whatsappMessageTemplate
          .replace('{date}', formatDate(event.date))
          .replace('{dayOfWeek}', getDayOfWeek(event.date))
          .replace('{time}', event.time)
          .replace('{location}', event.location)
          .replace('{details}', event.otherDetails || '')
          .replace('{maxParticipants}', event.maxParticipants.toString())
          .replace('{fee}', event.memberFee.toString())
          .replace('{route}', window.location.pathname.substring(1));
          
        message = encodeURIComponent(customMessage);
      } else {
        // Use default template
        message = encodeURIComponent(
          `日期: ${formatDate(event.date)} (${getDayOfWeek(event.date)})\n` +
          `時間: ${event.time}\n` +
          `地點: ${event.location}\n` +
          `用球: RSL Supreme\n` +
          `級別: ${event.otherDetails}\n` +
          `人數: ${event.maxParticipants}人\n` +
          `費用: $${event.memberFee}\n\n` +
          `報名請PM\n` +
          `或自助報名: https://matchpoint.hk/${window.location.pathname.substring(1)}`
        );
      }
    }
    
    // Use custom phone number if available
    const phoneNumber = siteSettings?.defaultSenderId || '85255053400';
    window.open(`https://wa.me/${phoneNumber}?text=${message}`, '_blank');
  };

  const handleManage = (e: React.MouseEvent) => {
    e.stopPropagation();
    onManage(event);
  };

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}/${month}/${day}`;
  };

  const getDayOfWeek = (dateString: string): string => {
    const daysOfWeek = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
    const date = new Date(dateString);
    return daysOfWeek[date.getDay()];
  };
  
  // Check if event is in the past
  const isPastEvent = (): boolean => {
    // Extract the end time from the format "19:00 - 21:00"
    const timeComponents = event.time.split(' - ');
    const endTimeString = timeComponents.length > 1 ? timeComponents[1] : timeComponents[0];
    
    // Create a date object with the event's date and end time
    const [hours, minutes] = endTimeString.split(':').map(Number);
    const eventDate = new Date(event.date);
    eventDate.setHours(hours, minutes, 0, 0);
    
    return eventDate < new Date();
  };
  
  // Determine card background color based on whether event is past or has available spots
  const cardStyle = {
    backgroundColor: isPastEvent() ? '#f5f5f5' : 'white',
    borderLeft: isPastEvent() 
      ? '4px solid #9e9e9e' 
      : (event.maxParticipants - event.participants?.reduce((sum, p) => sum + (p.spots ?? 0), 0) > 0 ? '4px solid #81c784' : '4px solid #d32f2f'),
    transition: 'transform 0.2s',
    '&:hover': {
      transform: 'scale(1.01)',
    },
    cursor: 'pointer'
  };

  const participantCount = event.participants?.reduce((sum, p) => sum + (p.spots ?? 0), 0) ?? 0;
  const availableSpots = event.maxParticipants - participantCount;

  return (
    <Card 
      sx={cardStyle} 
      onClick={() => onJoin(event)}
    >
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
          <Box>
            <Typography variant="h6" component="div">
              {formatDate(event.date)} ({getDayOfWeek(event.date)})
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {event.time} - {event.location}
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              {event.otherDetails}
            </Typography>
          </Box>
          <Box>
            <IconButton onClick={shareToWhatsApp} size="small">
              <WhatsAppIcon />
            </IconButton>
            {isAuthenticated && (
              <IconButton onClick={handleManage} size="small">
                <EditIcon />
              </IconButton>
            )}
          </Box>
        </Box>
        
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1 }}>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Chip 
              label={`$${event.memberFee}`} 
              size="small" 
              color="info" 
              variant="outlined" 
            />
            <Chip 
              label={`${participantCount}/${event.maxParticipants}人`} 
              size="small" 
              color={isPastEvent() ? "default" : (availableSpots > 0 ? "success" : "error")} 
              variant="outlined" 
            />
            {isPastEvent() && (
              <Chip 
                label="已結束" 
                size="small" 
                color="default" 
                variant="outlined" 
              />
            )}
          </Box>
          
          {!isPastEvent() && availableSpots > 0 && (
            <Button 
              variant="contained" 
              size="small" 
              startIcon={<PersonAddIcon />}
              onClick={(e) => {
                e.stopPropagation();
                onJoin(event);
              }}
              sx={{ bgcolor: '#81c784', '&:hover': { bgcolor: '#66bb6a' } }}
            >
              立即報名
            </Button>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default EventButton;