import { create } from 'zustand';

interface SignupStore {
  // Form data
  username: string;
  siteName: string;
  route: string;
  phone: string;
  password: string;
  confirmPassword: string;
  verificationCode: string;
  
  // UI states
  isVerified: boolean;
  isVerificationSent: boolean;
  
  // Error states
  usernameError: string;
  siteNameError: string;
  routeError: string;
  phoneError: string;
  verificationError: string;
  
  // Countdown
  countdown: number;
  
  // Actions
  setUsername: (username: string) => void;
  setSiteName: (siteName: string) => void;
  setRoute: (route: string) => void;
  setPhone: (phone: string) => void;
  setPassword: (password: string) => void;
  setConfirmPassword: (confirmPassword: string) => void;
  setVerificationCode: (code: string) => void;
  setIsVerified: (isVerified: boolean) => void;
  setIsVerificationSent: (isSent: boolean) => void;
  setUsernameError: (error: string) => void;
  setSiteNameError: (error: string) => void;
  setRouteError: (error: string) => void;
  setPhoneError: (error: string) => void;
  setVerificationError: (error: string) => void;
  setCountdown: (seconds: number) => void;
  decrementCountdown: () => void;
  reset: () => void;
}

export const useSignupStore = create<SignupStore>((set) => ({
  // Initial form data
  username: '',
  siteName: '',
  route: '',
  phone: '',
  password: '',
  confirmPassword: '',
  verificationCode: '',
  
  // Initial UI states
  isVerified: false,
  isVerificationSent: false,
  
  // Initial error states
  usernameError: '',
  siteNameError: '',
  routeError: '',
  phoneError: '',
  verificationError: '',
  
  // Initial countdown
  countdown: 0,
  
  // Actions
  setUsername: (username: string) => set({ username }),
  setSiteName: (siteName: string) => set({ siteName }),
  setRoute: (route: string) => set({ route }),
  setPhone: (phone: string) => set({ phone }),
  setPassword: (password: string) => set({ password }),
  setConfirmPassword: (confirmPassword: string) => set({ confirmPassword }),
  setVerificationCode: (code: string) => set({ verificationCode: code }),
  setIsVerified: (isVerified: boolean) => set({ isVerified }),
  setIsVerificationSent: (isSent: boolean) => set({ isVerificationSent: isSent }),
  setUsernameError: (error: string) => set({ usernameError: error }),
  setSiteNameError: (error: string) => set({ siteNameError: error }),
  setRouteError: (error: string) => set({ routeError: error }),
  setPhoneError: (error: string) => set({ phoneError: error }),
  setVerificationError: (error: string) => set({ verificationError: error }),
  setCountdown: (seconds: number) => set({ countdown: seconds }),
  decrementCountdown: () => set((state) => ({ 
    countdown: state.countdown > 0 ? state.countdown - 1 : 0 
  })),
  reset: () => set({
    username: '',
    siteName: '',
    route: '',
    phone: '',
    password: '',
    confirmPassword: '',
    verificationCode: '',
    isVerified: false,
    isVerificationSent: false,
    usernameError: '',
    siteNameError: '',
    routeError: '',
    phoneError: '',
    verificationError: '',
    countdown: 0,
  }),
}));