import React, { useEffect } from 'react';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { 
  Container, 
  Box, 
  Paper, 
  Typography,
  Stepper,
  Step,
  StepLabel,
  useTheme,
  useMediaQuery
} from '@mui/material';
import PersonalInfo from './signup/PersonalInfo';
import AccountSetup from './signup/AccountSetup';
import PhoneVerification from './signup/PhoneVerification';
import Confirmation from './signup/Confirmation';

function Signup() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  
  const steps = [
    { label: '個人資料', path: '/signup/personal-info' },
    { label: '帳戶設置', path: '/signup/account-setup' },
    { label: '驗證電話', path: '/signup/phone-verification' },
    { label: '確認', path: '/signup/confirmation' }
  ];

  const getCurrentStep = () => {
    return Math.max(
      0,
      steps.findIndex(step => location.pathname === step.path)
    );
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ 
        mt: 8, 
        mb: 8,
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center' 
      }}>
        <Paper elevation={3} sx={{ p: 4, width: '100%', borderRadius: 2 }}>
          <Typography component="h1" variant="h4" align="center" gutterBottom sx={{ fontWeight: 'bold', color: '#000' }}>
            註冊新帳戶
          </Typography>
          
          <Stepper 
            activeStep={getCurrentStep()} 
            alternativeLabel={!isMobile} 
            orientation={isMobile ? "vertical" : "horizontal"} 
            sx={isMobile ? { mt: 1, mb: 1 } : { mt: 3, mb: 4 }}
          >
            {steps.map(({ label }) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <Routes>
            <Route path="/" element={<Navigate to="/signup/personal-info" replace />} />
            <Route path="/personal-info" element={<PersonalInfo />} />
            <Route path="/account-setup" element={<AccountSetup />} />
            <Route path="/phone-verification" element={<PhoneVerification />} />
            <Route path="/confirmation" element={<Confirmation />} />
          </Routes>
        </Paper>
      </Box>
    </Container>
  );
}

export default Signup;
