import { useState, useEffect } from 'react';
import { Event } from '../../types/Event';
import { useAuth } from '../../contexts/AuthContext';
import axios from 'axios';
import { API_BASE_URL } from '../../config';
import { generateICSContent } from './utils';

export const useJoinModal = (initialEvent: Event, onClose: () => void, onEventUpdated: (updatedEvent: Event) => void) => {
  const [event, setEvent] = useState(initialEvent);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [spots, setSpots] = useState<number>(1);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showParticipants, setShowParticipants] = useState(false);
  const [showWaitingList, setShowWaitingList] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [requiresApproval, setRequiresApproval] = useState(false);
  const [isWaitlisted, setIsWaitlisted] = useState(false);
  
  const { isAuthenticated } = useAuth();
  
  // Derived state
  const currentParticipantCount = event.participants?.reduce((sum, p) => sum + (p.spots ?? 0), 0) ?? 0;
  const isEventFull = currentParticipantCount >= (event.maxParticipants ?? 0);
  const spotsLeft = Math.max(0, (event.maxParticipants ?? 0) - currentParticipantCount);
  
  // Load saved user data
  useEffect(() => {
    const savedName = localStorage.getItem('name');
    const savedPhone = localStorage.getItem('phone');
    if (savedName) setName(savedName);
    if (savedPhone) setPhone(savedPhone);
  }, []);

  // Input handlers
  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, '').slice(0, 8);
    setPhone(value);
  };

  const handleSpotsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value);
    if (!isNaN(value) && value >= 1 && value <= 10) {
      setSpots(value);
    }
  };

  // Validation
  const isValidHKPhoneNumber = (phoneNumber: string) => {
    const hkPhoneRegex = /^([2-9][0-9]{7})$/;
    return hkPhoneRegex.test(phoneNumber);
  };

  const isFormValid = () => {
    return name.trim() !== '' && isValidHKPhoneNumber(phone) && spots >= 1 && spots <= 10;
  };

  // Form submission
  const handleSubmit = async (e?: React.FormEvent) => {
    if (e) e.preventDefault();
    
    if (!isFormValid()) {
      setError('請填寫所有必填欄位並確保資料正確');
      return;
    }

    setError(null);
    setIsLoading(true);

    try {
      console.log('發送加入請求:', { name, phone, spots });
      const response = await axios.post(`${API_BASE_URL}/public/events/${event._id}/join`, { name, phone, spots });
      
      // Check if approval is required
      setRequiresApproval(response.data.requiresApproval || false);
      // Check if user was added to waitlist
      setIsWaitlisted(response.data.isWaitlisted || false);

      // Save name and phone to localStorage
      localStorage.setItem('name', name);
      localStorage.setItem('phone', phone);

      // Fetch the updated event data
      const headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
      
      let updatedEvent;
      if (isAuthenticated) {
        const updatedEventResponse = await axios.get(`${API_BASE_URL}/events/${event._id}`, { headers });
        updatedEvent = updatedEventResponse.data;
      } else {
        const updatedEventResponse = await axios.get(`${API_BASE_URL}/public/events/${event._id}`);
        updatedEvent = updatedEventResponse.data;
      }
      
      setEvent(updatedEvent);
      onEventUpdated(updatedEvent);
      setActiveStep(1); // Move to success step
    } catch (err) {
      console.error('錯誤回應:', err);
      if (axios.isAxiosError(err) && err.response) {
        setError(err.response.data.message || '加入活動失敗。請稍後再試。');
      } else {
        setError('發生意外錯誤。請稍後再試。');
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Calendar functionality
  const addToCalendar = () => {
    // Generate iCalendar content
    const icsContent = generateICSContent(event, spots);
    
    // Create a Blob with the iCalendar content
    const blob = new Blob([icsContent], { type: 'text/calendar;charset=utf-8' });
    
    // Create a link element to download the file
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${(event.location ?? '') || (event.description ?? '') || '活動'}.ics`;
    
    // Trigger download
    document.body.appendChild(link);
    link.click();
    
    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  };

  // Return all state and handlers
  return {
    // State
    event,
    name,
    setName,
    phone,
    spots,
    error,
    isLoading,
    showParticipants,
    setShowParticipants,
    showWaitingList,
    setShowWaitingList,
    activeStep,
    requiresApproval,
    isWaitlisted,
    isAuthenticated,
    
    // Derived state
    currentParticipantCount,
    isEventFull,
    spotsLeft,
    
    // Handlers
    handlePhoneChange,
    handleSpotsChange,
    handleSubmit,
    addToCalendar,
    isFormValid,
    onClose
  };
};