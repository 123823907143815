import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  TextField, 
  Box, 
  Button, 
  Alert, 
  CircularProgress, 
  InputAdornment 
} from '@mui/material';
import { Person, Web, Link } from '@mui/icons-material';
import { useSignupStore } from '../../stores/SignupStore';
import axios from 'axios';
import { API_BASE_URL } from '../../config';

function PersonalInfo() {
  const navigate = useNavigate();
  const [isLoading] = useState(false);
  const [error, setError] = useState('');
  const [isCheckingAvailability, setIsCheckingAvailability] = useState(false);
  
  const { 
    username, 
    siteName, 
    route,
    usernameError,
    siteNameError,
    routeError,
    setUsername,
    setSiteName,
    setRoute,
    setUsernameError,
    setSiteNameError,
    setRouteError,
  } = useSignupStore();

  // Debounce function
  const debounce = (func: Function, delay: number) => {
    let timeoutId: NodeJS.Timeout;
    return (...args: any[]) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  // Check availability functions
  const checkAvailability = async (field: 'username' | 'route' | 'siteName', value: string) => {
    if (!value.trim()) return;
    
    setIsCheckingAvailability(true);
    try {
      const payload = { [field]: value };
      const response = await axios.post(`${API_BASE_URL}/auth/check-availability`, payload);
      
      if (!response.data.success && response.data.errors) {
        if (field === 'username') {
          setUsernameError(response.data.errors.username);
        } else if (field === 'route') {
          setRouteError(response.data.errors.route);
        } else {
          setSiteNameError(response.data.errors.siteName);
        }
      } else {
        if (field === 'username') {
          setUsernameError('');
        } else if (field === 'route') {
          setRouteError('');
        } else {
          setSiteNameError('');
        }
      }
    } catch (err) {
      console.error('Error checking availability:', err);
    } finally {
      setIsCheckingAvailability(false);
    }
  };

  const debouncedCheckUsername = debounce((value: string) => checkAvailability('username', value), 500);
  const debouncedCheckRoute = debounce((value: string) => checkAvailability('route', value), 500);
  const debouncedCheckSiteName = debounce((value: string) => checkAvailability('siteName', value), 500);

  // Handle change functions
  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^[a-zA-Z0-9_]*$/.test(value)) {
      setUsername(value);
      if (value.trim()) {
        debouncedCheckUsername(value);
      } else {
        setUsernameError('');
      }
    }
  };

  const handleRouteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    if (/^[a-z0-9]*$/.test(value)) {
      setRoute(value);
      if (value.trim()) {
        debouncedCheckRoute(value);
      } else {
        setRouteError('');
      }
    }
  };

  const handleSiteNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSiteName(value);
    if (value.trim()) {
      debouncedCheckSiteName(value);
    } else {
      setSiteNameError('');
    }
  };

  // Validation
  const validateFields = () => {
    setError('');
    
    if (!username.trim()) {
      setError('請輸入用戶名');
      return false;
    }
    if (usernameError) {
      setError(usernameError);
      return false;
    }
    if (!siteName.trim()) {
      setError('請輸入網站名稱');
      return false;
    }
    if (siteNameError) {
      setError(siteNameError);
      return false;
    }
    if (!route.trim()) {
      setError('請輸入網站網址');
      return false;
    }
    if (routeError) {
      setError(routeError);
      return false;
    }
    return true;
  };

  const handleNext = () => {
    if (validateFields()) {
      navigate('/signup/account-setup');
    }
  };

  return (
    <Box component="form">
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <TextField
        margin="normal"
        required
        fullWidth
        label="用戶名"
        autoFocus
        value={username}
        onChange={handleUsernameChange}
        disabled={isLoading}
        error={!!usernameError}
        helperText={usernameError || '只能包含英文字母、數字、底線'}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Person color="secondary" />
            </InputAdornment>
          ),
          endAdornment: isCheckingAvailability && (
            <InputAdornment position="end">
              <CircularProgress size={20} />
            </InputAdornment>
          ),
        }}
      />
      
      <TextField
        margin="normal"
        required
        fullWidth
        label="網站名稱"
        value={siteName}
        onChange={handleSiteNameChange}
        disabled={isLoading}
        error={!!siteNameError}
        helperText={siteNameError || '請輸入您的網站名稱，此名稱將顯示在您的預約系統中'}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Web color="secondary" />
            </InputAdornment>
          ),
          endAdornment: isCheckingAvailability && (
            <InputAdornment position="end">
              <CircularProgress size={20} />
            </InputAdornment>
          ),
        }}
      />
      
      <TextField
        margin="normal"
        required
        fullWidth
        label="網站網址"
        value={route}
        onChange={handleRouteChange}
        disabled={isLoading}
        error={!!routeError}
        helperText={routeError || `只能包含小寫英文字母、數字。你的專屬網址將會是https://matchpoint.hk/${route}`}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Link color="secondary" />
            </InputAdornment>
          ),
          endAdornment: isCheckingAvailability && (
            <InputAdornment position="end">
              <CircularProgress size={20} />
            </InputAdornment>
          ),
        }}
      />

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
        <Button
          variant="contained"
          onClick={handleNext}
          disabled={isLoading}
        >
          下一步
        </Button>
      </Box>
    </Box>
  );
}

export default PersonalInfo;