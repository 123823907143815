import React from 'react';
import { 
  Typography, 
  Button, 
  List, 
  ListItem, 
  ListItemText, 
  Collapse, 
  Box, 
  Paper, 
  Chip 
} from '@mui/material';
import { Event } from '../../types/Event';

interface WaitingListProps {
  event: Event;
  isAuthenticated: boolean;
  showWaitingList: boolean;
  setShowWaitingList: (show: boolean) => void;
}

const WaitingList: React.FC<WaitingListProps> = ({ 
  event, 
  isAuthenticated, 
  showWaitingList, 
  setShowWaitingList 
}) => (
  <Box>
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1 }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          候補名單 ({event.waitingList.length} 人)
        </Typography>
      </Box>
      <Button
        size="small"
        variant="outlined"
        color="secondary"
        onClick={() => setShowWaitingList(!showWaitingList)}
      >
        {showWaitingList ? '隱藏名單' : '顯示名單'}
      </Button>
    </Box>
    
    <Collapse in={showWaitingList}>
      <Paper variant="outlined" sx={{ p: 2, borderRadius: 2 }}>
        <List dense disablePadding>
          {event.waitingList.map((waitlistEntry, index) => (
            <ListItem key={index} divider={index < event.waitingList!.length - 1}>
              <ListItemText
                primary={
                  <Typography variant="body1" sx={{ fontWeight: 'medium' }}>
                    {waitlistEntry.name} <Chip size="small" label={`${waitlistEntry.spots} 位`} sx={{ ml: 1 }} />
                  </Typography>
                }
                secondary={isAuthenticated ? `電話: ${waitlistEntry.phone}` : null}
              />
            </ListItem>
          ))}
        </List>
      </Paper>
    </Collapse>
  </Box>
);

export default WaitingList;