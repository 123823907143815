import React from 'react';
import { 
  Typography, 
  TextField, 
  Alert, 
  Paper 
} from '@mui/material';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

interface RegistrationFormProps {
  isEventFull: boolean;
  error?: string | null;
  name: string;
  setName: (name: string) => void;
  phone: string;
  handlePhoneChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  spots: number;
  handleSpotsChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: () => void;
}

const RegistrationForm: React.FC<RegistrationFormProps> = ({ 
  isEventFull, 
  error, 
  name, 
  setName, 
  phone, 
  handlePhoneChange, 
  spots, 
  handleSpotsChange, 
  handleSubmit 
}) => (
  <Paper elevation={0} sx={{ px: 3, py: 2, bgcolor: 'background.paper', borderRadius: 2 }}>
    <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
    </Typography>
    
    {isEventFull && (
      <Alert 
        severity="warning" 
        variant="outlined"
        sx={{ mb: 1 }}
        icon={<HourglassEmptyIcon />}
      >
        此活動已滿，您將被加入候補名單。如有名額空出，我們會通知您。
      </Alert>
    )}
    
    {error && (
      <Alert severity="error" sx={{ mb: 1 }}>
        {error}
      </Alert>
    )}
    
    <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
      <TextField
        margin="normal"
        id="name"
        label="姓名"
        type="text"
        fullWidth
        variant="outlined"
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
      />
      
      <TextField
        margin="normal"
        id="phone"
        label="電話號碼"
        type="tel"
        fullWidth
        variant="outlined"
        value={phone}
        onChange={handlePhoneChange}
        required
        inputProps={{
          maxLength: 8,
          pattern: "[2-9][0-9]{7}",
        }}
        helperText="請輸入8位數香港電話號碼 (例如: 23456789)"
      />
      
      <TextField
        margin="normal"
        id="spots"
        label="參與者數量"
        type="number"
        fullWidth
        variant="outlined"
        value={spots}
        onChange={handleSpotsChange}
        required
        inputProps={{
          min: 1,
          max: 10,
        }}
        helperText="請輸入1-10之間的參與者數量"
      />
    </form>
  </Paper>
);

export default RegistrationForm;