import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, Box, Switch, FormControlLabel, CircularProgress, Alert, Grid, List, ListItem, ListItemText, IconButton, InputAdornment } from '@mui/material';
import { Site, SiteSettings } from '../types/Site';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import { API_BASE_URL } from '../config';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import Phone from '@mui/icons-material/Phone';

interface BlacklistEntry {
  phone: string;
  reason: string;
  addedAt: Date;
}

const SiteManagement: React.FC = () => {
  const [site, setSite] = useState<Site | null>(null);
  const [name, setName] = useState('');
  const [settings, setSettings] = useState<SiteSettings>({
    defaultWhatsappTemplate: '',
    defaultSenderId: '',
    defaultSenderName: '',
    isSendWhatsappByDefault: false,
    requireApproval: false,
    whatsappMessageTemplate: ''
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isOwner, setIsOwner] = useState(false);
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const { route } = useParams<{ route: string }>();
  const [blacklistPhone, setBlacklistPhone] = useState('');
  const [blacklistReason, setBlacklistReason] = useState('');
  const [blacklist, setBlacklist] = useState<BlacklistEntry[]>([]);

  useEffect(() => {
    const fetchSite = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`${API_BASE_URL}/sites/my-site`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        const siteData = response.data;
        setSite(siteData);
        setName(siteData.name);
        setSettings(siteData.settings);
        setBlacklist(siteData.blacklist || []);
        setIsOwner(true);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status === 403) {
          setError('沒有權限管理此網站');
          setIsOwner(false);
        } else {
          setError('載入網站資料時發生錯誤');
        }
      } finally {
        setIsLoading(false);
      }
    };
    console.log('isAuthenticated:', isAuthenticated)
    if (isAuthenticated) {
      fetchSite();
    } else {
      navigate('/login');
    }
  }, [isAuthenticated, navigate]);

  const handleSettingsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    
    // Prevent changing defaultSenderId (phone number)
    if (name === 'defaultSenderId') {
      return;
    }
    
    setSettings(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isOwner) {
      setError('沒有權限更新此網站');
      return;
    }

    try {
      setError('');
      setSuccess('');
      setIsLoading(true);

      const data = {
        name,
        settings
      };

      if (site) {
        // Update existing site
        await axios.put(
          `${API_BASE_URL}/sites/${site._id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          }
        );
        setSuccess('網站設定已更新');
      } else {
        // Create new site
        await axios.post(
          `${API_BASE_URL}/sites`,
          data,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          }
        );
        setSuccess('網站已創建');
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        setError(err.response?.data.message || '更新網站時發生錯誤');
      } else {
        setError('更新網站時發生錯誤');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddToBlacklist = async () => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/sites/blacklist`,
        { phone: blacklistPhone, reason: blacklistReason },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      setBlacklist(response.data.blacklist);
      setBlacklistPhone('');
      setBlacklistReason('');
      setSuccess('已加入黑名單');
    } catch (err) {
      setError('加入黑名單失敗');
    }
  };

  const handleRemoveFromBlacklist = async (phone: string) => {
    try {
      const response = await axios.delete(
        `${API_BASE_URL}/sites/blacklist/${phone}`,
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      setBlacklist(response.data.blacklist);
      setSuccess('已從黑名單移除');
    } catch (err) {
      setError('從黑名單移除失敗');
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (!isOwner && !isLoading) {
    return (
      <Alert severity="error" sx={{ mt: 2 }}>
        沒有權限管理此網站
      </Alert>
    );
  }

  return (
    <Box sx={{ maxWidth: 600, mx: 'auto', p: 2 }}>
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate(`/${route}`)}
        sx={{ mb: 3 }}
      >
        返回活動列表
      </Button>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      {success && (
        <Alert severity="success" sx={{ mb: 2 }}>
          {success}
        </Alert>
      )}
      
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <Typography variant="h4" gutterBottom>
          {site ? '更新網站設定' : '創建網站'}
        </Typography>
        
        <TextField
          fullWidth
          label="網站名稱"
          value={name}
          onChange={(e) => setName(e.target.value)}
          margin="normal"
          disabled={isLoading}
          required
        />
        
        <TextField
          fullWidth
          label="聯絡電話"
          name="defaultSenderId"
          value={settings.defaultSenderId}
          onChange={handleSettingsChange}
          margin="normal"
          disabled={true}
          helperText="電話號碼不能被修改"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Phone color="secondary" />
                <Typography variant="body2" sx={{ ml: 1 }}>+852</Typography>
              </InputAdornment>
            ),
          }}
        />
        
        <TextField
          fullWidth
          label="發送者名稱"
          name="defaultSenderName"
          value={settings.defaultSenderName}
          onChange={handleSettingsChange}
          margin="normal"
          disabled={isLoading}
        />
        
        <FormControlLabel
          control={
            <Switch
              checked={settings.requireApproval}
              onChange={handleSettingsChange}
              name="requireApproval"
              color="primary"
            />
          }
          label="需要核准首次參加者"
        />
        
        <FormControlLabel
          control={
            <Switch
              checked={settings.isSendWhatsappByDefault}
              onChange={handleSettingsChange}
              name="isSendWhatsappByDefault"
              color="primary"
            />
          }
          label="預設發送WhatsApp訊息"
        />
        
        <TextField
          fullWidth
          label="WhatsApp訊息模板"
          name="whatsappMessageTemplate"
          value={settings.whatsappMessageTemplate || ''}
          onChange={handleSettingsChange}
          margin="normal"
          disabled={isLoading}
          multiline
          rows={4}
          helperText="可使用以下變數: {date}, {dayOfWeek}, {time}, {location}, {details}, {maxParticipants}, {fee}, {route}"
        />
        
        <Button 
          type="submit" 
          variant="contained" 
          color="primary" 
          sx={{ mt: 2 }}
          disabled={isLoading || !isOwner}
        >
          {isLoading ? <CircularProgress size={24} /> : (site ? '更新' : '創建')}網站
        </Button>
      </Box>

      <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
        黑名單管理
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            label="電話號碼"
            value={blacklistPhone}
            onChange={(e) => setBlacklistPhone(e.target.value.replace(/\D/g, '').slice(0, 8))}
            helperText="請輸入8位數香港電話號碼"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="原因"
            value={blacklistReason}
            onChange={(e) => setBlacklistReason(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Button
            variant="contained"
            onClick={handleAddToBlacklist}
            disabled={!/^[2-9]\d{7}$/.test(blacklistPhone)}
          >
            加入黑名單
          </Button>
        </Grid>
      </Grid>

      <List>
        {blacklist.map((entry) => (
          <ListItem
            key={entry.phone}
            secondaryAction={
              <IconButton edge="end" onClick={() => handleRemoveFromBlacklist(entry.phone)}>
                <DeleteIcon />
              </IconButton>
            }
          >
            <ListItemText
              primary={entry.phone}
              secondary={`原因: ${entry.reason} | 加入時間: ${new Date(entry.addedAt).toLocaleDateString()}`}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default SiteManagement;
