import React, { useState, useEffect, useCallback } from 'react';
import { Stack, CircularProgress, Alert, Button, Box, Typography, Backdrop, FormControlLabel, Switch } from '@mui/material';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { Event } from '../types/Event';
import EventButton from './EventButton';
import JoinModal from './JoinModal';
import ManageEventModal from './ManageEventModal';
import AddEventModal from './AddEventModal';
import { useAuth } from '../contexts/AuthContext';
import { API_BASE_URL } from '../config';
import LogoutIcon from '@mui/icons-material/Logout';

const EventList: React.FC = () => {
  const [events, setEvents] = useState<Event[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isAddEventModalOpen, setIsAddEventModalOpen] = useState(false);
  const [isJoinModalOpen, setIsJoinModalOpen] = useState(false);
  const [isManageModalOpen, setIsManageModalOpen] = useState(false);
  const [siteName, setSiteName] = useState<string>('');
  const [siteNameLoading, setSiteNameLoading] = useState(true);
  const [siteNameError, setSiteNameError] = useState<string | null>(null);
  const [isOwner, setIsOwner] = useState(false);
  const [showAllEvents, setShowAllEvents] = useState(false);
  
  const { isAuthenticated, isLoading, checkAuth, logout } = useAuth();
  const { route } = useParams<{ route: string }>();
  const navigate = useNavigate();

  const fetchSiteName = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/public/site/${route}`);
      setSiteName(response.data.siteName);
      
      if (isAuthenticated) {
        const authResponse = await axios.get(`${API_BASE_URL}/sites/${route}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        setIsOwner(authResponse.data.isOwner);
      }
      
      setSiteNameLoading(false);
    } catch (err) {
      console.error('Error loading site name:', err);
      if (axios.isAxiosError(err) && err.response?.status === 404) {
        setSiteNameError(`找不到網站 "${route}"。`);
      } else {
        setSiteNameError('無法載入網站名稱。');
      }
      setSiteNameLoading(false);
    }
  }, [isAuthenticated, route]);

  const fetchEvents = useCallback(async () => {
    try {
      const headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      };
      if (isAuthenticated && localStorage.getItem('token') !== undefined) {
        const response = await axios.get(
          `${API_BASE_URL}/events/all/${route}${showAllEvents ? '?all=true' : ''}`, 
          { headers }
        );
        setEvents(response.data);
      } else {
        const response = await axios.get(`${API_BASE_URL}/public/site/${route}/events`);
        setEvents(response.data);
      }
      setLoading(false);
    } catch (err) {
      console.error('載入活動時發生錯誤:', err);
      setError('無法載入活動。請稍後再試。');
      setLoading(false);
    }
  }, [isAuthenticated, route, showAllEvents]);

  useEffect(() => {
    const initializeAuth = async () => {
      await checkAuth();
      fetchEvents();
      fetchSiteName();
    };

    initializeAuth();
  }, [checkAuth, fetchEvents, fetchSiteName]);

  useEffect(() => {
    if (siteName) {
      document.title = `${siteName} | MatchPoint`;
    }
  }, [siteName]);

  const handleEventUpdated = () => {
    fetchEvents();
  };

  const handleJoinClick = (event: Event) => {
    setSelectedEvent(event);
    setIsJoinModalOpen(true);
  };

  const handleManageClick = (event: Event) => {
    setSelectedEvent(event);
    setIsManageModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedEvent(null);
    setIsJoinModalOpen(false);
    setIsManageModalOpen(false);
  };

  const handleCloseAddEventModal = () => {
    setIsAddEventModalOpen(false);
  };

  const handleEventAdded = () => {
    fetchEvents();
  };

  const handleEventDeleted = () => {
    setIsManageModalOpen(false);
    fetchEvents();
  };

  const handleLogout = () => {
    logout();
    setIsOwner(false);
  };

  const handleToggleAllEvents = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowAllEvents(event.target.checked);
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchEvents();
    }
  }, [showAllEvents, fetchEvents, isAuthenticated]);

  if (isLoading || loading || siteNameLoading) {
    return (
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={true}
      >
        <CircularProgress color="inherit" />
        <Typography variant="h6" sx={{ mt: 2 }}>載入中...</Typography>
      </Backdrop>
    );
  }

  if (siteNameError) {
    return (
      <Box sx={{ textAlign: 'center', my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          網站未找到
        </Typography>
      </Box>
    );
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <>
      {isOwner && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<LogoutIcon />}
            onClick={handleLogout}
            size="small"
          >
            登出
          </Button>
        </Box>
      )}
      
      <Box sx={{ textAlign: 'center', my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          {siteName}
        </Typography>
      </Box>
      
      {isOwner && (
        <Box sx={{ display: 'flex', gap: 1, mb: 2, flexWrap: 'wrap', alignItems: 'center' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsAddEventModalOpen(true)}
          >
            新增活動
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate(`/${route}/site-management`)}
          >
            網站設定
          </Button>
          <FormControlLabel
            control={
              <Switch
                checked={showAllEvents}
                onChange={handleToggleAllEvents}
                color="primary"
              />
            }
            label="顯示所有活動"
          />
        </Box>
      )}
      
      <Stack spacing={2}>
        {events.map((event) => (
          <EventButton 
            key={event._id}
            event={event} 
            onJoin={() => handleJoinClick(event)}
            onManage={() => handleManageClick(event)}
            isAuthenticated={isOwner}
            siteSettings={typeof event.site === 'object' ? event.site.settings : {}}
          />
        ))}
      </Stack>
      {selectedEvent && isJoinModalOpen && (
        <JoinModal
          event={selectedEvent}
          onClose={handleCloseModal}
          onEventUpdated={handleEventUpdated}
        />
      )}
      {selectedEvent && isManageModalOpen && isOwner && (
        <ManageEventModal
          event={selectedEvent}
          onClose={handleCloseModal}
          onEventUpdated={handleEventUpdated}
          onEventDeleted={handleEventDeleted}
        />
      )}
      {isAddEventModalOpen && isOwner && (
        <AddEventModal
          onClose={handleCloseAddEventModal}
          onEventAdded={handleEventAdded}
        />
      )}
    </>
  );
};

export default EventList;
