import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  TextField, 
  Box, 
  Button, 
  Alert, 
  InputAdornment, 
  IconButton 
} from '@mui/material';
import { Phone, Lock, Visibility, VisibilityOff } from '@mui/icons-material';
import { useSignupStore } from '../../stores/SignupStore';

function AccountSetup() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState('');
  const [isLoading] = useState(false);
  
  const { 
    phone,
    password,
    confirmPassword,
    phoneError,
    setPhone,
    setPassword,
    setConfirmPassword,
    setPhoneError
  } = useSignupStore();

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 8) {
      setPhone(value);
      setPhoneError('');
    }
  };

  const validateFields = () => {
    setError('');

    if (!phone) {
      setError('請輸入電話號碼');
      return false;
    }
    if (phone.length !== 8) {
      setError('電話號碼必須為8位數字');
      return false;
    }
    if (!password) {
      setError('請輸入密碼');
      return false;
    }
    if (password.length < 8) {
      setError('密碼長度必須至少為8個字符');
      return false;
    }
    if (password !== confirmPassword) {
      setError('兩次輸入的密碼不一致');
      return false;
    }
    return true;
  };

  const handleNext = () => {
    if (validateFields()) {
      navigate('/signup/phone-verification');
    }
  };

  return (
    <Box component="form">
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <TextField
        margin="normal"
        required
        fullWidth
        label="電話號碼"
        value={phone}
        onChange={handlePhoneChange}
        disabled={isLoading}
        error={!!phoneError}
        helperText={phoneError || '請輸入8位數字的香港手機號碼'}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Phone color="secondary" />
              <span style={{ marginLeft: 8 }}>+852</span>
            </InputAdornment>
          ),
        }}
      />

      <TextField
        margin="normal"
        required
        fullWidth
        label="密碼"
        type={showPassword ? 'text' : 'password'}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        disabled={isLoading}
        helperText="密碼長度必須至少為8個字符"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Lock color="secondary" />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <TextField
        margin="normal"
        required
        fullWidth
        label="確認密碼"
        type={showConfirmPassword ? 'text' : 'password'}
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        disabled={isLoading}
        helperText="請再次輸入密碼"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Lock color="secondary" />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                edge="end"
              >
                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
        <Button
          variant="outlined"
          onClick={() => navigate('/signup/personal-info')}
          disabled={isLoading}
        >
          上一步
        </Button>
        <Button
          variant="contained"
          onClick={handleNext}
          disabled={isLoading}
        >
          下一步
        </Button>
      </Box>
    </Box>
  );
}

export default AccountSetup;