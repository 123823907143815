import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  CircularProgress,
  Divider,
  IconButton,
  useTheme,
  useMediaQuery,
  Box,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material';
import { Event } from '../../types/Event';
import CloseIcon from '@mui/icons-material/Close';

import EventDetails from './EventDetails';
import ParticipantsList from './ParticipantsList';
import WaitingList from './WaitingList';
import RegistrationForm from './RegistrationForm';
import ConfirmationView from './ConfirmationView';

interface JoinModalUIProps {
  event: Event;
  name: string;
  setName: (name: string) => void;
  phone: string;
  spots: number;
  error: string | null;
  isLoading: boolean;
  showParticipants: boolean;
  setShowParticipants: (show: boolean) => void;
  showWaitingList: boolean;
  setShowWaitingList: (show: boolean) => void;
  activeStep: number;
  requiresApproval: boolean;
  isWaitlisted: boolean;
  isAuthenticated: boolean;
  currentParticipantCount: number;
  isEventFull: boolean;
  spotsLeft: number;
  handlePhoneChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSpotsChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (e?: React.FormEvent) => void;
  addToCalendar: () => void;
  isFormValid: () => boolean;
  onClose: () => void;
}

const JoinModalUI: React.FC<JoinModalUIProps> = ({
  event,
  name,
  setName,
  phone,
  spots,
  error,
  isLoading,
  showParticipants,
  setShowParticipants,
  showWaitingList,
  setShowWaitingList,
  activeStep,
  requiresApproval,
  isWaitlisted,
  isAuthenticated,
  currentParticipantCount,
  isEventFull,
  spotsLeft,
  handlePhoneChange,
  handleSpotsChange,
  handleSubmit,
  addToCalendar,
  isFormValid,
  onClose,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const steps = ['報名資料', '報名完成'];

  const renderRegistrationForm = () => (
    <Box>
      <EventDetails 
        event={event}
        currentParticipantCount={currentParticipantCount}
        spotsLeft={spotsLeft}
        isEventFull={isEventFull}
      />

      <Divider sx={{ my: 2 }} />

      <ParticipantsList 
        event={event}
        isAuthenticated={isAuthenticated}
        showParticipants={showParticipants}
        setShowParticipants={setShowParticipants}
      />

      {event.waitingList && event.waitingList.length > 0 && (
        <WaitingList 
          event={event}
          isAuthenticated={isAuthenticated}
          showWaitingList={showWaitingList}
          setShowWaitingList={setShowWaitingList}
        />
      )}

      <Divider sx={{ my: 2 }} />

      <RegistrationForm 
        isEventFull={isEventFull}
        error={error}
        name={name}
        setName={setName}
        phone={phone}
        handlePhoneChange={handlePhoneChange}
        spots={spots}
        handleSpotsChange={handleSpotsChange}
        handleSubmit={handleSubmit}
      />
    </Box>
  );

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return renderRegistrationForm();
      case 1:
        return (
          <ConfirmationView 
            event={event}
            spots={spots}
            isWaitlisted={isWaitlisted}
            requiresApproval={requiresApproval}
            isAuthenticated={isAuthenticated}
            addToCalendar={addToCalendar}
            onClose={onClose}
          />
        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <Dialog 
      open={true} 
      onClose={activeStep === 1 ? onClose : undefined}
      maxWidth="md" 
      fullWidth
      fullScreen={isMobile}
      PaperProps={{
        sx: {
          borderRadius: isMobile ? 0 : 2,
        }
      }}
    >
      <DialogTitle sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        borderBottom: 1, 
        borderColor: 'divider',
        py: 2
      }}>
        <Typography variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
          {activeStep === 0 ? '活動報名' : '報名完成'}
        </Typography>
        <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      
      <DialogContent sx={{ p: 3 }}>
        <Stepper activeStep={activeStep} sx={{ mt: 1, mb: 3 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        
        {getStepContent(activeStep)}
      </DialogContent>
      
      {activeStep === 0 && (
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button onClick={onClose} sx={{ mr: 1 }}>
            取消
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            disabled={!isFormValid() || isLoading}
            startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : null}
          >
            {isLoading ? '處理中...' : isEventFull ? '加入候補名單' : '確認報名'}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default JoinModalUI;